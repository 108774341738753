body {
  width:80%;
  margin-left:auto;
  margin-right:auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* remove rounding from cards, buttons and inputs */
.card,
.btn,
.form-control {
  border-radius: 0;
}
.btn {
  background-color: #ff9900;
  border: 0px;
}

.btn:hover {
  background-color: #FFAC31;
  border: 0px;
}